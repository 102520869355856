import React from 'react'
import SentIconSmall from '../images/sent-icon-small.inline.svg'
import { FaRegCheckCircle } from 'react-icons/fa'

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const EmailOnlyForm = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showConf: false,  email: {value: "", valid: false} };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window.location.href.indexOf("email-submitted") > -1 && !this.state.showConf) {
      this.setState({showConf: true});
    }
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "email", "email" : this.state.email.value })
    })
      .then(() => {
        this.setState({showConf: true});
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => {
    let emailReg = /\S+@\S+\.\S+/;
    this.setState({ email: { value: e.target.value, valid: emailReg.test(e.target.value) } });
  }

  render() {
    const { email } = this.state;

    return (
      <div className="email-only-form-container">
        { this.state.showConf ?
          <div className="email-only-conf">
            <SentIconSmall/>
            <div className="conf-text">
              <strong>Thanks for signing up!</strong>
              <p>You’ll be the first to know when the K Effect app releases.</p>
            </div>
          </div>
        :
          <div className="email-only-form">
              <form onSubmit={this.handleSubmit} name="email" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="email" />
                <input name="bot-field"  style={{opacity: 0, height: 0, margin: 0}} />
                <div className="form-row">
                  <input type="email" name="email" placeholder="Email Address" value={email.value} onChange={this.handleChange} required/>
                  <button className="btn-filled" type="submit">Sign up</button>
                  { this.state.email.valid ? <div className="check-container animate__animated animate__bounceIn"><FaRegCheckCircle/></div> : ""}
                </div>
            </form>
          </div>
        }
      </div>
    )
  }
}

export default EmailOnlyForm
