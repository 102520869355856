import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useState } from 'react';
import Layout from '../components/layout'
import FromSection from '../components/FormSection'
import HeroSection from '../components/HeroSection'
import ResultsSection from '../components/ResultsSection'
import FirstDaySection from '../components/FirstDaySection'
import FeaturesSection from '../components/FeaturesSection'
import KEffectSection from '../components/KEffectSection'
import ClosingSection from '../components/ClosingSection'
import '@brightoak/embrk-react-components/dist/index.css'

import { Element } from 'react-scroll'

export const IndexPageTemplate = ({
  heroSection,
  resultsSection,
  videoSection,
  firstDaySection,
  featuresSection,
  kEffectSection,
  waitingForSection,
}) => {
  const [triggerPopUp, setTriggerPopUp] = useState(false);
  const [triggerConfPopUp, setTriggerConfPopUp] = useState(false);

  return (
    <div>
      <FromSection showPupUp={triggerPopUp} forceShowConf={triggerConfPopUp} forceShowPupUp={() => setTriggerPopUp(true)} forceClosePopUp={() => setTriggerPopUp(false)} checkForSingUp={false} />
      <HeroSection heroData={heroSection} forceShowPopUp={() => setTriggerPopUp(true)}  forceShowConfPopUp={() => {
        setTriggerPopUp(true);
        setTriggerConfPopUp(true);
      } }/>
      <ResultsSection resultsData={resultsSection} videoData={videoSection}/>
      <FirstDaySection firstDayData={firstDaySection}/>
      <Element name="scroll-bands">
        <FeaturesSection featuresData={featuresSection} forceShowPopUp={() => setTriggerPopUp(true)}/>
      </Element>
      <Element name="scroll-k-effect">
        <KEffectSection effectData={kEffectSection}/>
      </Element>
      <ClosingSection closingData={waitingForSection} forceShowPopUp={() => setTriggerPopUp(true)}/>
    </div>
  )
}


IndexPageTemplate.propTypes = {
  heroSection: PropTypes.object,
  resultsSection: PropTypes.object,
  videoSection: PropTypes.object,
  firstDaySection: PropTypes.object,
  featuresSection: PropTypes.object,
  kEffectSection: PropTypes.object,
  waitingForSection: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        heroSection={frontmatter.heroSection}
        resultsSection={frontmatter.resultsSection}
        videoSection={frontmatter.videoSection}
        firstDaySection={frontmatter.firstDaySection}
        featuresSection={frontmatter.featuresSection}
        kEffectSection={frontmatter.kEffectSection}
        waitingForSection={frontmatter.waitingForSection}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heroSection {
          heading
          paragraph
          heroImage {
            childImageSharp {
              fixed(width: 962) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        resultsSection {
          resultItems {
            title
            paragraph
            iconType
          }
        }
        videoSection {
          desktopVimeoId,
          mobileVimeoId,
        }
        firstDaySection {
          dayItems {
            title
            paragraph
            image {
              childImageSharp {
                fixed(width: 208) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        featuresSection {
          featureItems {
            title
            paragraph
            featureType
            ctaType
          }
        }
        kEffectSection {
          content
          boldContent
        }
        waitingForSection {
          paragraph
        }
      }
    }
  }
`
