import React from 'react'
import PropTypes from "prop-types"
import MobileText from "../images/resutls-text-mobile.inline.svg"
import DesktopText from "../images/resutls-text-desktop.inline.svg"
import HeartIcon from "../images/results-icon-heart.inline.svg"
import ArmIcon from "../images/results-icon-arm.inline.svg"
import PhoneIcon from "../images/results-icon-phone.inline.svg"
import TeamIcon from "../images/results-icon-team.inline.svg"
import LineIcon from "../images/line.inline.svg"
import LineIconDesktop from "../images/line-desktop.inline.svg"

const ResultsSection = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mobile: false};
  }
  
  componentDidMount() {
    if ( window.innerWidth <= 767) {
      this.setState({mobile: true});
    }
  }

// const ResultsSection = ({resultsData, videoData}) => (
  render() {
    return (
      <div className="results-section">
        <div className="results-top-section">
          <MobileText/>
          <DesktopText/>
          <div className="results-items-list">
            {this.props.resultsData.resultItems.map((item) => (
              <div className="results-item">
                <div className="result-icon">
                  {item.iconType === "heart" ? <HeartIcon/> : item.iconType === " arm" ? <ArmIcon/> : item.iconType === "phone" ? <PhoneIcon/> : <TeamIcon/>}
                </div>
                <div className="result-text">
                  <strong>{item.title}</strong>
                  <p>{item.paragraph}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="line-container">
          <LineIcon/>
          <LineIconDesktop/>
        </div>
        <div className="video-container">
          <iframe className="vimeo-iframe" src={"https://player.vimeo.com/video/" + (this.state.mobile ? this.props.videoData.mobileVimeoId : this.props.videoData.desktopVimeoId ) } frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
    )
  }
}

ResultsSection.propTypes = {
  resultsData: PropTypes.object,
  videoData: PropTypes.object,
}


export default ResultsSection
