import React from 'react'
import PropTypes from "prop-types"
import MobileText from "../images/closing-text-mobile.inline.svg"
import DesktopText from "../images/closing-text-desktop.inline.svg"
import GymImg from '../images/gym.png'

const ClosingSection = ({closingData, forceShowPopUp}) => (
  <div className="closing-section">
    <div className="img-container">
      <img src={ GymImg }/>
    </div>
    <div className="text-section">
      <MobileText/>
      <DesktopText/>
      <p>{closingData.paragraph}</p>
      <div className="btn-filled" onClick={() => forceShowPopUp()}>Click Here for 1 FREE Class</div>
    </div>
  </div>
)

ClosingSection.propTypes = {
  closingData: PropTypes.object,
  forceShowPopUp: PropTypes.func,
}


export default ClosingSection
