import React from 'react'
import PropTypes from "prop-types"
import Feature1 from '../images/feature1.png'
import Feature2 from '../images/feature2.png'
import Feature3 from '../images/feature3.png'
import FeatureIcon1 from '../images/feature-icon-1.inline.svg'
import FeatureIcon2 from '../images/feature-icon-2.inline.svg'
import FeatureIcon3 from '../images/feature-icon-3.inline.svg'
import Feature2IconImg from '../images/feature-icon-2.png'
import EmailOnlyForm from './EmailOnlyForm'

import { Element } from 'react-scroll'

const FeaturesSection = ({featuresData, forceShowPopUp}) => (
  <div className="features-section">
    {featuresData.featureItems.map((item) => (
      <div className="feature-item">
        { item.featureType === "app" ? <Element name="scroll-app"></Element>  : ""}
        <img className="feature-img" src={item.featureType === "monitor" ? Feature1 : item.featureType === "app" ? Feature2 : Feature3 } />
        <div className="feature-info">
          <div className="feature-icon">
            {item.featureType === "monitor" ? <FeatureIcon1/> : item.featureType === "app" ? <img src={Feature2IconImg} /> : <FeatureIcon3/>}
          </div>
          <div className="feature-text">
            <div className="title-container">
              <strong>{item.title}</strong>
            </div>
            <p>{item.paragraph}</p>
          </div>
          <div className="feature-cta">
            {item.ctaType === "signUp" ?
              <EmailOnlyForm/>
            : item.ctaType === "join" ?
              <div className="btn-filled" onClick={() => forceShowPopUp()}>Join a Team</div>
            : ""
            }
          </div>
        </div>
      </div>
    ))}
  </div>
)

FeaturesSection.propTypes = {
  featuresData: PropTypes.object,
  forceShowPopUp: PropTypes.func,
}


export default FeaturesSection
