import React from 'react'
import PropTypes from "prop-types"
import MobileText from "../images/first-day-text-mobile.inline.svg"
import DesktopText from "../images/first-day-text-desktop.inline.svg"
import Outline1 from "../images/circle1.inline.svg"
import Outline2 from "../images/circle2.inline.svg"
import Outline3 from "../images/circle3.inline.svg"
import Outline4 from "../images/circle4.inline.svg"
import SpacerLineMobile from '../images/spacer-line-mobile.inline.svg'
import SpacerLineDesktop1 from '../images/spacer-line-desktop-1.inline.svg'
import SpacerLineDesktop2 from '../images/spacer-line-desktop-2.inline.svg'
import SpacerLineDesktop3 from '../images/spacer-line-desktop-3.inline.svg'
import Lines from '../images/bottom-lines.inline.svg'
import Img from 'gatsby-image'

const FirstDaySection = ({firstDayData}) => (
  <div className="first-day-section">
    <MobileText/>
    <DesktopText/>
    <div className="items">
      {firstDayData.dayItems.map((item, index) => (
        <div className="result">
          <div className="results-item">
            <div className="day-img">
              { index === 0 ?  <Outline1/> : index === 1 ? <Outline2/> : index === 2 ? <Outline3/> : <Outline4/> }
              <Img fixed={item.image.childImageSharp.fixed} />
            </div>
            <div className="day-text">
              <strong>{item.title}</strong>
              <p>{item.paragraph}</p>
            </div>
          </div>
          { index === 0 ? <SpacerLineDesktop1/> : index === 1 ? <SpacerLineDesktop2/> : index === 2 ? <SpacerLineDesktop3/> : "" }
          { index !== firstDayData.dayItems.length - 1 ?  <SpacerLineMobile/> : "" }
        </div>
      ))}
    </div>
    <Lines/>
  </div>
)

FirstDaySection.propTypes = {
  firstDayData: PropTypes.object,
}


export default FirstDaySection
