import React from 'react'
import PropTypes from "prop-types"
// import HeroImg from '../images/hero-img.png'
import HeroImg from '../images/hero-workout-img.png'
import HeroImgBackground from '../images/hero-background.inline.svg'
import ConsultationForm from './ConsultationForm'

const HeroSection = ({heroData, forceShowPopUp, forceShowConfPopUp}) => (
  <div className="hero-section">
    <div className="side-padding">
      <h1>{heroData.heading}</h1>
      <p>{heroData.paragraph}</p>
      <div className="challenge-txt">
        <h4>Up for the challenge? </h4>
        <h4 className="blue">Sign up Today!</h4>
      </div>
      <div className="hero-form">
       <ConsultationForm onSubmitCallback={ () => forceShowConfPopUp() }/>
      </div>
      <div className="btn-filled mobile-only" onClick={() => forceShowPopUp()}>
       Click Here for 1 FREE Class!
      </div>
    </div>
    <div className="hero-img-container">
      <img className="hero-img" src={HeroImg}/>
      <HeroImgBackground/>
    </div>
  </div>
)

HeroSection.propTypes = {
  heroData: PropTypes.object,
  forceShowPopUp: PropTypes.func,
  forceShowConfPopUp: PropTypes.func,
}


export default HeroSection
