import React from 'react'
import PropTypes from "prop-types"
import MobileText from "../images/k-effect-text-mobile.inline.svg"
import DesktopText from "../images/k-effect-text-desktop.inline.svg"
import KohlerImg from '../images/kohler.jpeg'

const KEffectSection = ({effectData}) => (
  <div className="k-effect-section">
    <MobileText/>
    <DesktopText/>
    <div className="photo-section">
      <div className="img-container">
       <img src={ KohlerImg }/>
      </div>
      <div className="text-section">
        <p>{effectData.content}</p>
        <strong>{effectData.boldContent}</strong>
      </div>
    </div>
  </div>
)

KEffectSection.propTypes = {
  effectData: PropTypes.object,
}


export default KEffectSection
